import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import Teaser from '~components/Teaser'
import styles from '~styles/components/listings.module.scss'

export default class Listings extends Component {
  render () {
    const { items } = this.props.data

    return (
      <section className={classNames('section section-listings')}>
        <div className="grid-container">
          <header className={styles.header}>
            <h2 className={styles.heading}>
              {this.props.data.title}
            </h2>
          </header>

          <div className={classNames(styles.items, 'grid-x grid-margin-x')}>
            {items && items.map((item, index) => (
              <div className={classNames(styles.item, 'cell medium-9 large-4')} key={`listing-${index}`}>
                <Teaser
                  title={item.title}
                  alertLevel={item.alertLevel}
                  country={item.country}
                  vaccinationRules={this.props.vaccinationRules}
                  destination={item.slug}
                  image={item.featuredImage.fixed}
                  url={item.url}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

Listings.propTypes = {
  vaccinationRules: PropTypes.object,
  data: PropTypes.object
}

export const query = graphql`
  fragment Destinations on ContentfulSectionDestinations {
    id
    title
    items {
      id
      title
      slug
      country {
        id
        name
        countryCode
        toggleVaccinationStatus
        outboundTestingTitle
        outboundTestingText
        outboundTestingLink
        outboundQuarantineTitle
        outboundQuarantineText
        outboundQuarantineLink
        inboundTestingTitle
        inboundTestingText
        inboundTestingLink
        inboundQuarantineTitle
        inboundQuarantineText
        inboundQuarantineLink
        vaccinatedTestingTitle
        vaccinatedTestingText
        vaccinatedTestingLink
        vaccinatedQuarantineTitle
        vaccinatedQuarantineText
        vaccinatedQuarantineLink
      }
      alertLevel
      url
      featuredImage {
        title
        fixed(width: 398, height: 262, quality: 80) {
          srcSet
          srcSetWebp
          src
        }
      }
    }
  }
`

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import Image from '../Image'
import RichText from '../RichText'
import ButtonLink from '../../components/ButtonLink'
import styles from '~styles/components/signpost.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

export default class Signpost extends Component {
  render () {
    const isEvenSignpost = this.props.position % 2 !== 0
    const orderCss = isEvenSignpost ? styles.normal : styles.reverse
    const contentOffsetCss = isEvenSignpost ? '' : 'large-offset-1 xlarge-offset-2'

    return (
      <section className={classNames(styles.signpost, orderCss, 'section section-signpost')}>
        <div className="grid-container">
          <div className={classNames(styles.inner)}>
            <div className={classNames(styles.content, styles.grey, 'grid-x')}>
              <div className={classNames(contentOffsetCss, 'cell large-11 xlarge-10')}>
                {this.props.data.icon ? <img className={styles.icon} src={this.props.data.icon.file.url} alt={this.props.data.icon.title} /> : null}

                {this.props.data.title && (
                  <h2 className={styles.title}>
                    {this.props.data.title}
                  </h2>)}

                <div className={classNames(editorStyles.editor, styles.text)}>
                  <RichText json={this.props.data.text.json} />
                </div>

                <ButtonLink
                  href={this.props.data.buttonLink}
                  title={`${this.props.data.buttonLabel}: ${this.props.data.title}`}
                  aria-label={`${this.props.data.buttonLabel}: ${this.props.data.title}`}
                  text={this.props.data.buttonLabel}
                  className={styles.button}
                />
              </div>
            </div>

            <Image
              image={this.props.data.image.fixed}
              alt={this.props.data.image.title || this.props.data.title}
              className={styles.media}
            />
          </div>
        </div>
      </section>
    )
  }
}

Signpost.propTypes = {
  data: PropTypes.object,
  position: PropTypes.number
}

export const query = graphql`
  fragment Signpost on ContentfulSectionSignpost {
    id
    title
    icon {
      file {
        fileName
        url  
      }
      title
    }
    text {
      json
    }
    image {
      title
      fixed(width: 800, height: 600, quality: 80) {
        src
        srcSet
        srcSetWebp
      }
    }
    buttonLabel
    buttonLink
  }
`

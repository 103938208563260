import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styles from '~styles/components/singleImage.module.scss'

export default class SingleImage extends Component {
  render () {
    return (
      <section className="section section-single-image">
        <div className="grid-container">
          <picture className={styles.media}>
            <source srcSet={this.props.data.image.tablet.srcSetWebp} type="image/webp" media="(min-width: 43.125em)" />
            <source srcSet={this.props.data.image.tablet.srcSet} type="image/jpeg" media="(min-width: 43.125em)" />
            <img src={this.props.data.image.thumb.src} srcSet={this.props.data.image.thumb.srcSet} alt={this.props.data.image.title} className={styles.image} />
          </picture>
        </div>
      </section>
    )
  }
}

SingleImage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment SingleImage on ContentfulSectionSingleImage {
    id
    image {
      title
      description
      tablet : fixed(width: 1440, height: 851, quality: 80) {
        src
        srcSet
        srcSetWebp
      }
      thumb : fixed(width: 640, height: 789, quality: 80) {
        src
        srcSet
        srcSetWebp
      }
    }
  }
`

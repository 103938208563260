import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import RichText from '../RichText'
import styles from '~styles/components/usps.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

export default class Usps extends Component {
  render () {
    return (
      <div className={classNames('section section-usps')}>
        <div className="grid-container">
          <div className={classNames('grid-x grid-margin-x', styles.items)}>
            {this.props.data.items.map((usp) => (
              <div className={classNames('cell large-4', styles.item)} key={usp.id}>
                <div className={styles.content}>
                  <span className={styles.icon}>
                    <img className={styles.sprite} src={usp.icon.file.url} alt={usp.icon.title} />
                  </span>

                  <h2 className={styles.title}>
                    {usp.title}
                  </h2>

                  <div className={classNames(editorStyles.editor, styles.text)}>
                    <RichText json={usp.text.json} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

Usps.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment UsPs on ContentfulSectionUsPs {
    id
    items {
      id
      icon {
        file {
          fileName
          url  
        }
        title
      }
      title
      text {
        json
      }
    }
  }
`

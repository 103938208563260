import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '~styles/components/pill.module.scss'

const Pill = (props) => (
  <span
    aria-label={`${props.status} list`}
    className={classNames(
      styles.pill,
      styles[props.status.toLowerCase()],
      { [styles.highlight]: props.highlight },
      props.className
    )}
  >
    {`${props.status} list`}
  </span>
)

Pill.propTypes = {
  status: PropTypes.string,
  highlight: PropTypes.bool,
  className: PropTypes.string
}

Pill.defaultProps = {
  status: 'Green'
}

export default Pill

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Link from '~components/Link'
import classNames from 'classnames'
import styles from '~styles/components/cta.module.scss'
import buttonStyles from '~styles/components/button.module.scss'

export default class Cta extends Component {
  render () {
    return (
      <section className={classNames('section section-cta', styles.cta)}>
        <div className={classNames(styles.outer)}>
          <div className={classNames('grid-container', styles.inner)}>
            <div className={classNames(styles.content, 'grid-x')}>
              <div className={classNames('cell large-10 xlarge-8')}>
                {this.props.data.title && (
                  <h2 className={classNames(styles.title)}>
                    {this.props.data.title}
                  </h2>
                )}

                {this.props.data.subtitle && (
                  <span className={styles.subtitle}>
                    {this.props.data.subtitle}
                  </span>
                )}

                {this.props.data.buttonLink && (
                  <Link
                    href={this.props.data.buttonLink}
                    className={classNames(buttonStyles.button, buttonStyles.white, styles.button)}
                  >
                    {this.props.data.buttonLabel}
                  </Link>
                )}
              </div>
            </div>
          </div>

          {this.props.data.image && (
            <picture className={styles.media}>
              <source srcSet={this.props.data.image.desktop.srcSetWebp} type="image/webp" media="(min-width: 79.6875em)" />
              <source srcSet={this.props.data.image.desktop.srcSet} type="image/jpeg" media="(min-width: 79.6875em)" />
              <source srcSet={this.props.data.image.tablet.srcSetWebp} type="image/webp" media="(min-width: 43.125em)" />
              <source srcSet={this.props.data.image.tablet.srcSet} type="image/jpeg" media="(min-width: 43.125em)" />
              <img src={this.props.data.image.thumb.src} srcSet={this.props.data.image.thumb.srcSet} alt={this.props.data.image.title} className={styles.image} />
            </picture>
          )}
        </div>
      </section>
    )
  }
}

Cta.propTypes = {
  data: PropTypes.object,
  apiPrice: PropTypes.string
}

export const query = graphql`
  fragment Cta on ContentfulSectionCta {
    id
    title
    subtitle
    image {
      title
      desktop: fixed(width: 1600, height: 550, quality: 80) {
        src
        srcSet
        srcSetWebp
      }
      tablet: fixed(width: 1100, height: 378, quality: 80) {
        src
        srcSet
        srcSetWebp
      }
      thumb: fixed(width: 480, height: 480, quality: 80) {
        src
        srcSet
        srcSetWebp
      }
    }
    buttonLabel
    buttonLink
  }
`

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import RichText from '../RichText'
import AccordionItem from '~components/AccordionItem'
import styles from '~styles/components/accordion.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

export default class Accordion extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeItem: null
    }
  }

  onClick (id, e) {
    e.preventDefault()

    let activeItem = id

    if (this.state.activeItem === activeItem) {
      activeItem = null
    }

    this.setState({ activeItem })
  }

  render () {
    return (
      <section className={classNames(styles.accordion, 'section section-accordion grey')}>
        <div className="grid-container">
          <div className="grid-x grid-smedium-center">
            <div className={classNames('cell smedium-10 large-8', styles.container)}>
              <h2 className={styles.title}>
                {this.props.data.title}
              </h2>

              {this.props.data.text &&
                <div className={classNames(editorStyles.editor, styles.text)}>
                  <RichText json={this.props.data.text.json} />
                </div>}

              <div className={styles.items}>
                {this.props.data.items.map(item => (
                  <AccordionItem
                    key={item.id}
                    item={item} active={this.state.activeItem === item.id}
                    onClick={this.onClick.bind(this, item.id)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

Accordion.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment Accordion on ContentfulSectionAccordion {
    id
    title
    text {
      json
    }
    items {
      id
      title
      text {
        json
      }
    }
  }
`

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toggleVaccinationStatus } from '~redux/actions/global'
import classNames from 'classnames'
import styles from '~styles/components/vaccineStatus.module.scss'

class VaccineStatus extends Component {
  constructor (props) {
    super()

    this.checkboxRef = React.createRef()

    this.state = {
      value: props.vaccinated
    }
  }

  handleOnChange (value) {
    this.props.toggleVaccinationStatus(value)
  }

  componentDidUpdate (prevProps) {
    if (this.props.vaccinated !== prevProps.vaccinated) {
      this.setState({ value: this.props.vaccinated })
    }
  }

  render () {
    return (
      <label className={classNames(styles.label, this.props.className, { [styles.active]: this.state.value })}>
        Are you fully vaccinated?

        <input
          ref={this.checkboxRef}
          type="checkbox"
          className={styles.checkbox}
          checked={this.state.value}
          onChange={() => this.handleOnChange(!this.state.value)}
        />
      </label>
    )
  }
}

VaccineStatus.propTypes = {
  dispatch: PropTypes.func,
  toggleVaccinationStatus: PropTypes.func,
  vaccinated: PropTypes.bool,
  className: PropTypes.string
}

const mapStateToProps = (state) => ({
  vaccinated: state.global.vaccinated
})

export default connect(mapStateToProps, { toggleVaccinationStatus })(VaccineStatus)

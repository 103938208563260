import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import RichText from '../RichText'
import styles from '~styles/components/supporting-text.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

export default class SupportingText extends Component {
  render () {
    const { data } = this.props

    return (
      <section className={classNames('section section-supporting-text')}>
        <div className="grid-container">
          <header className={styles.header}>
            {data.icon && (
              <img src={data.icon.file.url} alt={data.icon.title || data.title} className={styles.icon} />
            )}

            <h2 className={styles.title}>
              {data.title}
            </h2>
          </header>

          <div className={classNames(styles.items, 'grid-x grid-margin-x grid-margin-y')}>
            <div className={classNames(styles.item, 'cell large-6')}>
              <div className={classNames(editorStyles.editor)}>
                <RichText json={data.text.json} />
              </div>
            </div>

            {data.additionalText && (
              <div className={classNames(styles.item, 'cell large-6')}>
                <div className={classNames(editorStyles.editor)}>
                  <RichText json={data.additionalText.json} />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

SupportingText.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment SupportingText on ContentfulSectionSupportingText {
    id
    title
    icon {
      file {
        fileName
        url  
      }
      title
    }
    additionalText {
      json
    }
    text {
      json
    }
  }
`

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from './Link'
import styles from '~styles/components/button.module.scss'

export default class ButtonLink extends Component {
  render () {
    const { ...props } = this.props

    return (
      <Link
        {...props}
        href={this.props.href}
        text={this.props.text}
        className={classNames(styles.button, this.props.className)}
      >
        {this.props.children}
      </Link>
    )
  }
}

ButtonLink.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.any
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Image extends Component {
  render () {
    return (
      <picture className={this.props.className}>
        <source srcSet={this.props.image.srcSetWebp} type="image/webp" />
        <source srcSet={this.props.image.srcSet} type="image/jpeg" />
        <img
          src={this.props.image.src}
          srcSet={this.props.image.srcSet}
          alt={this.props.alt}
        />
      </picture>
    )
  }
}

Image.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string
  }),
  className: PropTypes.string
}

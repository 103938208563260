/* eslint-disable camelcase */
import React, { Component } from 'react'
import Link from '~components/Link'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Sprite from '~components/Sprite'
import Image from '~components/Image'
import ButtonLink from '~components/ButtonLink'
import Pill from '~components/Pill'
import VaccineStatus from '~components/VaccineStatus'
import { connect } from 'react-redux'
import styles from '~styles/components/teaser.module.scss'
import buttonStyles from '~styles/components/button.module.scss'

class Teaser extends Component {
  constructor () {
    super()

    this.state = {
      activeTab: 0
    }
  }

  switchTab (index, e) {
    this.setState({
      activeTab: index
    })
  }

  getCurrentTab () {
    return this.state.activeTab
  }

  isVaccinated () {
    const { vaccinated, country } = this.props
    const { toggleVaccinationStatus } = country
    return vaccinated && toggleVaccinationStatus
  }

  render () {
    const { country, vaccinationRules } = this.props
    const { toggleVaccinationStatus } = country

    return (
      <section className={classNames(styles.teaser)}>
        <div className={styles.thumb}>
          <h3 className={styles.title}>
            {this.props.title}
          </h3>

          <span className={classNames(styles.subtitle)}>
            {country.name}
          </span>

          <Pill
            status={this.props.alertLevel}
            highlight={true}
            className={styles.pill}
          />

          <Image
            image={this.props.image}
            className={styles.image}
            alt={this.props.image.title || this.props.title}
          />
        </div>

        <div className={styles.content}>
          <div className={styles.buttons}>
            <button
              type="button"
              aria-label="Departing from BHX"
              disabled={this.state.activeTab === 0}
              onClick={() => this.switchTab(0)}
              className={classNames(
                styles.button,
                styles.buttonTab,
                buttonStyles.button,
                { [styles.buttonTabActive]: this.state.activeTab === 0 },
                { [buttonStyles.white]: this.state.activeTab !== 0 }
              )}
            >
              Departing from BHX
            </button>

            <button
              type="button"
              aria-label="Arriving back into BHX"
              disabled={this.state.activeTab === 1}
              onClick={() => this.switchTab(1)}
              className={classNames(
                styles.button,
                styles.buttonTab,
                buttonStyles.button,
                { [styles.buttonTabActive]: this.state.activeTab === 1 },
                { [buttonStyles.white]: this.state.activeTab !== 1 }
              )}
            >
              Arriving back into BHX
            </button>

          </div>

          {toggleVaccinationStatus && (
            <VaccineStatus className={styles.vaccinated} />
          )}

          <div className={styles.tabs}>
            {(this.getCurrentTab() === 0 && !this.isVaccinated()) && (
              <div className={classNames(styles.tab)}>
                <div className={styles.tabItems}>
                  <div className={styles.tabItem}>
                    {country.inboundTestingTitle && (
                      <span className={styles.tabTitle}>
                        {country.inboundTestingTitle}
                      </span>
                    )}

                    <span className={styles.tabIcon}>
                      <Sprite name="test" />
                    </span>

                    <span className={styles.tabText}>
                      {country.inboundTestingText}
                    </span>

                    {country.inboundTestingLink && (
                      <Link
                        href={country.inboundTestingLink}
                        className={styles.tabLink}
                      >
                        Find out more

                        <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                          <Sprite name="external" />
                        </span>
                      </Link>
                    )}
                  </div>

                  <div className={styles.tabItem}>
                    {country.inboundQuarantineTitle && (
                      <span className={styles.tabTitle}>
                        {country.inboundQuarantineTitle}
                      </span>
                    )}

                    <span className={styles.tabIcon}>
                      <Sprite name="quarantine" />
                    </span>

                    <span className={styles.tabText}>
                      {country.inboundQuarantineText}
                    </span>

                    {country.inboundQuarantineLink && (
                      <Link
                        href={country.inboundQuarantineLink}
                        className={styles.tabLink}
                      >
                        Find out more

                        <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                          <Sprite name="external" />
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}

            {(this.getCurrentTab() === 0 && this.isVaccinated()) && (
              <div className={classNames(styles.tab)}>
                <div className={styles.tabItems}>
                  <div className={styles.tabItem}>
                    {country.vaccinatedTestingTitle && (
                      <span className={styles.tabTitle}>
                        {country.vaccinatedTestingTitle}
                      </span>
                    )}

                    <span className={styles.tabIcon}>
                      <Sprite name="test" />
                    </span>

                    <span className={styles.tabText}>
                      {country.vaccinatedTestingText}
                    </span>

                    {country.vaccinatedTestingLink && (
                      <Link
                        href={country.vaccinatedTestingLink}
                        className={styles.tabLink}
                      >
                        Find out more

                        <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                          <Sprite name="external" />
                        </span>
                      </Link>
                    )}
                  </div>

                  <div className={styles.tabItem}>
                    {country.vaccinatedQuarantineTitle && (
                      <span className={styles.tabTitle}>
                        {country.vaccinatedQuarantineTitle}
                      </span>
                    )}

                    <span className={styles.tabIcon}>
                      <Sprite name="quarantine" />
                    </span>

                    <span className={styles.tabText}>
                      {country.vaccinatedQuarantineText}
                    </span>

                    {country.vaccinatedQuarantineLink && (
                      <Link
                        href={country.vaccinatedQuarantineLink}
                        className={styles.tabLink}
                      >
                        Find out more

                        <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                          <Sprite name="external" />
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}

            {(this.getCurrentTab() === 1 && !this.isVaccinated()) && (
              <div className={classNames(styles.tab)}>
                <div className={styles.tabItems}>
                  <div className={styles.tabItem}>
                    {country.outboundTestingTitle && (
                      <span className={styles.tabTitle}>
                        {country.outboundTestingTitle}
                      </span>
                    )}

                    <span className={styles.tabIcon}>
                      <Sprite name="test" />
                    </span>

                    <span className={styles.tabText}>
                      {country.outboundTestingText}
                    </span>

                    {country.outboundTestingLink && (
                      <Link
                        href={country.outboundTestingLink}
                        className={styles.tabLink}
                      >
                        Find out more

                        <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                          <Sprite name="external" />
                        </span>
                      </Link>
                    )}
                  </div>

                  <div className={styles.tabItem}>
                    {country.outboundQuarantineTitle && (
                      <span className={styles.tabTitle}>
                        {country.outboundQuarantineTitle}
                      </span>
                    )}

                    <span className={styles.tabIcon}>
                      <Sprite name="quarantine" />
                    </span>

                    <span className={styles.tabText}>
                      {country.outboundQuarantineText}
                    </span>

                    {country.outboundQuarantineLink && (
                      <Link
                        href={country.outboundQuarantineLink}
                        className={styles.tabLink}
                      >
                        Find out more

                        <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                          <Sprite name="external" />
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}

            {(this.getCurrentTab() === 1 && this.isVaccinated()) && (
              <div className={classNames(styles.tab)}>
                <div className={styles.tabItems}>
                  <div className={styles.tabItem}>
                    {vaccinationRules.outboundTestingTitle && (
                      <span className={styles.tabTitle}>
                        {vaccinationRules.outboundTestingTitle}
                      </span>
                    )}

                    <span className={styles.tabIcon}>
                      <Sprite name="test" />
                    </span>

                    <span className={styles.tabText}>
                      {vaccinationRules.outboundTestingText}
                    </span>

                    {vaccinationRules.outboundTestingLink && (
                      <Link
                        href={vaccinationRules.outboundTestingLink}
                        className={styles.tabLink}
                      >
                        Find out more

                        <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                          <Sprite name="external" />
                        </span>
                      </Link>
                    )}
                  </div>

                  <div className={styles.tabItem}>
                    {vaccinationRules.outboundQuarantineTitle && (
                      <span className={styles.tabTitle}>
                        {vaccinationRules.outboundQuarantineTitle}
                      </span>
                    )}

                    <span className={styles.tabIcon}>
                      <Sprite name="quarantine" />
                    </span>

                    <span className={styles.tabText}>
                      {vaccinationRules.outboundQuarantineText}
                    </span>

                    {vaccinationRules.outboundQuarantineLink && (
                      <Link
                        href={vaccinationRules.outboundQuarantineLink}
                        className={styles.tabLink}
                      >
                        Find out more

                        <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                          <Sprite name="external" />
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={styles.bottom}>
            <span className={styles.bottomTitle}>
              Covid guide
            </span>

            <span className={styles.bottomText}>
              Keep in the know with the
              <br />
              latest covid news
            </span>

            <ButtonLink
              href={`/destinations/${this.props.destination}`}
              className={classNames(styles.bottomButton)}
            >
              Read the covid guide
            </ButtonLink>
          </div>
        </div>
      </section>
    )
  }
}

Teaser.propTypes = {
  title: PropTypes.string.isRequired,
  alertLevel: PropTypes.string.isRequired,
  country: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  destination: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  vaccinationRules: PropTypes.object,
  vaccinated: PropTypes.bool
}

Teaser.defaultProps = {
  buttonLabel: 'Vist guide'
}

const mapStateToProps = (state) => ({
  vaccinated: state.global.vaccinated
})

export default connect(mapStateToProps)(Teaser)

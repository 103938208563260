import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

class Head extends Component {
  hasOpenGraphImage () {
    const { image } = this.props
    return image && image.openGraph
  }

  render () {
    return (
      <>
        <Helmet>
          <html lang="en" />
          <title>
            {`${this.props.siteName} | ${this.props.title}`}
          </title>
          <meta name="title" content={`${this.props.siteName} | ${this.props.title}`} />
          <meta name="description" content={this.props.description} />
          <link rel="canonical" href={this.props.location.href} />

          <meta property="og:locale" content="en_GB" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${this.props.siteName} | ${this.props.title}`} />
          <meta property="og:description" content={this.props.description} />
          <meta property="og:url" content={this.props.location.href} />
          <meta property="og:site_name" content={this.props.siteName} />

          <meta name="twitter:site" content="@bhx_official" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={this.props.location.href} />
          <meta property="twitter:title" content={`${this.props.siteName} | ${this.props.title}`} />
          <meta property="twitter:description" content={this.props.description} />
        </Helmet>

        {this.hasOpenGraphImage() && (
          <Helmet>
            <meta property="og:image" content={`https:${this.props.image.openGraph.src}`} />
            <meta property="twitter:image" content={`https:${this.props.image.openGraph.src}`} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="627" />
          </Helmet>
        )}
      </>
    )
  }
}

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  siteName: PropTypes.string,
  image: PropTypes.object,
  location: PropTypes.object
}

Head.defaultProps = {
  siteName: 'Birmingham Airport'
}

export default Head

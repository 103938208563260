import _Accordion from './Accordion'
import _Media from './Media'
import _Signpost from './Signpost'
import _SingleImage from './SingleImage'
import _Text from './Text'
import _UsPs from './Usps'
import _Table from './Table'
import _SupportingText from './SupportingText'
import _Tabs from './Tabs'
import _Cta from './Cta'
import _Listings from './Listings'

export const Accordion = _Accordion
export const Media = _Media
export const Signpost = _Signpost
export const SingleImage = _SingleImage
export const Text = _Text
export const UsPs = _UsPs
export const Table = _Table
export const SupportingText = _SupportingText
export const Tabs = _Tabs
export const Cta = _Cta
export const Destinations = _Listings

const components = {
  Accordion,
  Media,
  Signpost,
  SingleImage,
  Text,
  UsPs,
  Table,
  SupportingText,
  Tabs,
  Cta,
  Destinations
}

export function use (name) {
  return components[name.replace(/^ContentfulSection/, '')] || null
}

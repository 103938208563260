import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import styles from '~styles/components/tabs.module.scss'
import AccordionItem from '~components/AccordionItem'

export default class Tabs extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeItem: 0,
      activeTab: 0
    }
  }

  onClick (id, e) {
    e.preventDefault()

    let activeItem = id

    if (this.state.activeItem === activeItem) {
      activeItem = null
    }

    this.setState({ activeItem })
  }

  switchTab (index, e) {
    this.setState({
      activeTab: index,
      activeItem: 0
    })
  }

  render () {
    const { items } = this.props.data

    return (
      <div className={classNames(styles.wrapper, 'section section-tabs')}>
        <div className="grid-container">
          <div className="grid-x grid-small-center">
            <div className={classNames(styles.content, 'cell')}>
              <Swiper
                className={styles.carousel}
                spaceBetween={0}
                slidesPerView="auto"
                watchOverflow={true}
                preventClicks={false}
              >
                {items && items.map((item, index) => {
                  return (
                    <SwiperSlide key={item.id} className={styles.slide}>
                      <button
                        type="button"
                        className={classNames(styles.title, { [styles.active]: this.state.activeTab === index })}
                        onClick={() => this.switchTab(index)}
                      >
                        {item.title}
                      </button>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>

            <div className={classNames(styles.content, 'cell large-8')}>
              {items && (
                <div className={styles.tabs}>
                  {items.map((tab, index) => (
                    this.state.activeTab === index && (
                      <div key={`tab-${tab.index}`} className={classNames(styles.tab)}>
                        {tab.items.map((accordion, accordionIndex) => (
                          <AccordionItem
                            key={accordion.id}
                            item={accordion}
                            active={this.state.activeItem === accordionIndex}
                            onClick={this.onClick.bind(this, accordionIndex)}
                            className={styles.accordion}
                          />
                        ))}
                      </div>
                    )
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Tabs.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment Tabs on ContentfulSectionTabs {
    id
    items {
      id
      title
      items {
        id
        title
        text {
          json
          id
        }
      }
    }
  }
`

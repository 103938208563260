import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import styles from '~styles/components/table.module.scss'

export default class Table extends Component {
  render () {
    const { table } = this.props.data
    const tableData = table.tableData
    const header = tableData.length && tableData.slice(0, 1)
    const body = tableData.length && tableData.slice(1)

    return (
      <section className={classNames('section section-table')}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x grid-small-center">
            <div className="cell xlarge-10">
              <div className={styles.wrapper}>
                {table && (
                  <table cellSpacing="0" className={styles.table}>
                    <thead>
                      {header.map((row, rowIndex) => (
                        <tr className={styles.header} key={`head-${rowIndex}`}>
                          {row.map((item, itemIndex) => (
                            <th className={styles.tableHeader} key={`th-${itemIndex}`}>
                              {item}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody>
                      {body.map((row, rowIndex) => (
                        <tr className={styles.row} key={`body-${rowIndex}`}>
                          {row.map((item, itemIndex) => (
                            <td className={styles.tableData} key={`td-${itemIndex}`}>
                              {item}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

Table.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment Table on ContentfulSectionTable {
    id
    title
    table {
      tableData
    }
  }
`

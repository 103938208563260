import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Youtube from 'react-youtube'
import classNames from 'classnames'
import styles from '~styles/components/media.module.scss'

export default class Media extends Component {
  render () {
    const matches = this.props.data.media.match(/v=([a-zA-Z0-9_-]{11})/)

    if (matches.length < 2) {
      return null
    }

    return (
      <section className={classNames('section section-media', styles.media)}>
        <div className="grid-container">
          <Youtube videoId={matches[1]} containerClassName={'responsive-embed widescreen'} />
        </div>
      </section>
    )
  }
}

Media.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment Media on ContentfulSectionMedia {
    id
    media
  }
`

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import RichText from '~components/RichText'
import classNames from 'classnames'
import Sprite from '~components/Sprite'
import styles from '~styles/components/accordion.module.scss'

export default class AccordionItem extends Component {
  render () {
    const height = this.props.active ? 'auto' : 0

    return (
      <div className={classNames(styles.item, this.props.className)}>
        <h3 className={styles.subtitle} onClick={this.props.onClick}>
          <button type="button" className={styles.heading}>
            {this.props.item.title}

            <span
              aria-hidden={true}
              className={classNames('icon', styles.icon)}
            >
              <Sprite name={this.props.active ? 'minus' : 'plus'} />
            </span>
          </button>
        </h3>

        <AnimateHeight duration={300} height={height}>
          <div className={styles.itemContent}>
            <RichText json={this.props.item.text.json} />
          </div>
        </AnimateHeight>
      </div>
    )
  }
}

AccordionItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.object
  }).isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import RichText from '../RichText'
import editorStyles from '~styles/components/editor.module.scss'

export default class Text extends Component {
  render () {
    return (
      <section className={classNames('section section-text')}>
        <div className="grid-container">
          {this.props.data.content && (
            <div className={classNames(editorStyles.editor)}>
              <RichText json={this.props.data.content.json} />
            </div>
          )}
        </div>
      </section>
    )
  }
}

Text.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment Text on ContentfulSectionText {
    id
    content {
      json
    }
  }
`
